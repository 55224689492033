import { inject, Injectable } from '@angular/core';
import { LOGIN_URL, LOGOUT_URL, REFRESH_URL, USER_CHECK_URL } from '../../urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, first, map, tap } from 'rxjs/operators';
import { get, set } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfo } from '../index';

@Injectable()
export class AuthService {
	private readonly ACCESS_TOKEN = 'access_token';
	private readonly REFRESH_TOKEN = 'refresh_token';
	private readonly BEARER = 'Bearer ';

	public redirectUrl: string;

	private profileSource = new Subject<any>();
	profileChanged = this.profileSource.asObservable();
	private _profile: any;

	private _router = inject(Router);
	private _http = inject(HttpClient);
	private _jwtService = inject(JwtHelperService);

	constructor() {
		this.redirectUrl = this._profileDefaultUrl();
	}

	checkUser(): Observable<any> {
		return this._http.get(USER_CHECK_URL).pipe(
			tap((data: any) => this._handleUser(data)),
			map((data2: any) => {
				const { user, profile } = data2;
				set(user, 'profile', profile);
				return user;
			})
		);
	}

	login(user: any) {
		return this._http
			.post(
				LOGIN_URL,
				{},
				{ params: { username: user.username, password: user.password } }
			)
			.pipe(
				tap(tokens => this._storeTokens(tokens)),
				map(_ => true),
				catchError(_ => {
					this._doLogoutUser();
					return of(false);
				})
			);
	}

	logout(): Observable<any> {
		return this._http.post<any>(LOGOUT_URL, {}).pipe(
			first(),
			tap(_ => this._doLogoutUser()),
			map(_ => true),
			catchError(_ => of(false))
		);
	}

	getAccessToken(): any {
		return localStorage.getItem(this.ACCESS_TOKEN);
	}

	isLoggedIn(): boolean {
		const token = this.getAccessToken();
		return !!token && !this._jwtService.isTokenExpired(token);
	}

	refreshTokenIsValid(): boolean {
		const token = this.getRefreshToken();
		return !!token && !this._jwtService.isTokenExpired(token);
	}

	shouldLogout(): boolean {
		return !this.refreshTokenIsValid() && !this.getAccessToken();
	}

	private _profileDefaultUrl(): string {
		return get(this._profile, 'defaultRoute') || '/';
	}

	private _handleUser(data: UserInfo): void {
		const { user, profile } = data;

		this._profile = profile;

		set(user, 'profile', profile);

		localStorage.setItem('user', JSON.stringify(user));
		localStorage.setItem('profile', JSON.stringify(profile));

		this.redirectUrl = this._profileDefaultUrl();
	}

	refreshToken(token: string): Observable<any> {
		return this._http
			.post<any>(
				`${REFRESH_URL}`,
				{},
				{
					headers: new HttpHeaders({
						Authorization: this.BEARER + token,
					}),
				}
			)
			.pipe(tap(tokens => this._storeTokens(tokens)));
	}

	private _storeTokens(tokens: any) {
		localStorage.setItem(this.ACCESS_TOKEN, tokens.access_token);
		localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
	}

	public getRefreshToken(): string {
		return localStorage.getItem(this.REFRESH_TOKEN);
	}

	private _doLogoutUser() {
		this._router.navigateByUrl('/login').then(_ => {
			this._removeTokens();
			this._removeUserDefinedData();
		});
	}

	private _removeTokens() {
		localStorage.removeItem(this.ACCESS_TOKEN);
		localStorage.removeItem(this.REFRESH_TOKEN);
	}

	logoutOthers(token: any, all: boolean = false): Observable<any> {
		return this._http.get(`/api/user/logout-others`, {
			params: { token, all },
		});
	}

	private _removeUserDefinedData() {
		localStorage.removeItem('WORKFLOW_VIEW');
		localStorage.removeItem('EXAM_COLUMNS');
		localStorage.removeItem('profile');
		localStorage.removeItem('user');
		localStorage.removeItem('wf');
		localStorage.removeItem('wf_filter');
		localStorage.removeItem('scheduler_table');
		localStorage.removeItem('last_filter');
		localStorage.removeItem('last_filter_sc');
	}
}
